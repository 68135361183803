import { useRef } from "react";

import { css } from "@emotion/react";

import Link from "next/link";

import { Button, Props as ButtonProps } from "components/shared/library/Button";

const style = {
  hidden: css({ display: "hidden" }),
};

type Props = {
  href: string;
  sameTab?: boolean;
  stopPropagation?: boolean;
  download?: string;
};

export const ButtonLink = ({
  href,
  sameTab,
  stopPropagation,
  download,
  ...buttonProps
}: Props & Omit<ButtonProps, "onClick">) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  return (
    <div css={buttonProps.isFullWidth && css({ width: "100%" })}>
      {download ? (
        // we should not use nextJs Link component for non-navigation purposes
        <a ref={linkRef} href={href} download={download} css={style.hidden} />
      ) : (
        <Link
          ref={linkRef}
          target={sameTab ? "_self" : "_blank"}
          href={href}
          css={style.hidden}
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
          }}
        />
      )}
      <Button
        {...buttonProps}
        onClick={(e) => {
          if (stopPropagation) e.stopPropagation();
          linkRef?.current?.click();
        }}
      />
    </div>
  );
};
