export const placeholderAvatar = "personal-profile-placeholder.png";

export const placeholderOrgLogo = "org-profile-placeholder.png";

export const emojiLocalAvatars = [
  "/images/emoji_blue.png",
  "/images/emoji_green.png",
  "/images/emoji_pink.png",
  "/images/emoji_purple.png",
  "/images/emoji_yellow.png",
];
