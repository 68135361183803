import { reduce } from "lodash";

/**
 * A util that returns a random value out of an array
 * @param array an array of anything
 * @returns one random value out of the array
 */
export const getRandom = <T>(array: T[]) => {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
};

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getPercentageDiscount = (initial: number, final: number) => {
  const ratio = (final * 100) / initial;
  // round to nearest percent
  return Math.round(100 - ratio);
};

// Generate a numeric value from the UUID characters
export const hashUuidToNumber = (uuid: string) => {
  return reduce(uuid, (acc, char) => acc + char.charCodeAt(0), 0);
};
