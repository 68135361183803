import { Tooltip as ToolTipAntd } from "antd";

import React, { useState } from "react";

import { SerializedStyles } from "@emotion/react";

import { white } from "constants/colors";

import { Typography } from "./Typography";

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  showTooltip?: "onHover" | "always" | "never";
  position?: "top" | "bottom" | "right" | "left";
  customCss?: {
    wrapper?: SerializedStyles;
  };
};

export const Tooltip = ({
  children,
  content,
  showTooltip = "onHover",
  position = "top",
  customCss,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <ToolTipAntd
      placement={position}
      open={
        (showTooltip === "onHover" && isHovered) || showTooltip === "always"
          ? true
          : false
      }
      color={white}
      title={
        typeof content === "string" ? (
          <Typography size="XXS">{content}</Typography>
        ) : (
          content
        )
      }
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        data-testid="tooltip-wrapper"
        css={customCss?.wrapper}
      >
        {children}
      </div>
    </ToolTipAntd>
  );
};
